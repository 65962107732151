$(function(){

    $('#content01_bg').show();
    $('#content02_bg').hide();

    $(window).scroll(function(){
        if ($(window).scrollTop() > 1) {
            // していれば、pageTopをfadeIn
            $('#content02_bg').show();
            $('#content01_bg').hide();
        } 

        if ($(window).scrollTop() > 300) {
            // していれば、pageTopをfadeIn
            $('#content01_bg').show();
            $('#content02_bg').hide();
        } 
        if ($(window).scrollTop() > 600) {
            // していれば、pageTopをfadeIn
            $('#content02_bg').show();
            $('#content01_bg').hide();
        }
        if ($(window).scrollTop() > 900) {
            // していれば、pageTopをfadeIn
            $('#content01_bg').show();
            $('#content02_bg').hide();
        }
        if ($(window).scrollTop() > 1200) {
            // していれば、pageTopをfadeIn
            $('#content02_bg').show();
            $('#content01_bg').hide();
        }
        if ($(window).scrollTop() > 1500) {
            // していれば、pageTopをfadeIn
            $('#content01_bg').show();
            $('#content02_bg').hide();
        }
        if ($(window).scrollTop() > 1800) {
            // していれば、pageTopをfadeIn
            $('#content01_bg').show();
            $('#content02_bg').hide();
        }
        if ($(window).scrollTop() > 2100) {
            // していれば、pageTopをfadeIn
            $('#content01_bg').show();
            $('#content02_bg').hide();
        }
        if ($(window).scrollTop() > 2400) {
            // していれば、pageTopをfadeIn
            $('#content01_bg').show();
            $('#content02_bg').hide();
        }
        if ($(window).scrollTop() > 2700) {
            // していれば、pageTopをfadeIn
            $('#content01_bg').show();
            $('#content02_bg').hide();
        }
    });
});

